import React from 'react';

const description = <p> La pièce <em>Le confessionnal</em> a pour but de premier de susciter des réflexions sur l’anxiété que peut provoquer le passage à l’âge adulte. « Qu’est-ce que je vais faire de ma vie? » « Est-ce que mes parents seront fiers de moi? » « Pourrai-je être enfin celle ou celui que je suis vraiment après le secondaire? » On se pose tant de questions sur le chemin de la quête de soi. Parfois, cette quête de soi peut être douloureuse, pouvant créer chez certains des troubles anxieux ou autres troubles mentaux.
                    <br/><br/>
                    <div>Sous la forme d’un théâtre réaliste et introspectif, <em>Le confessionnal</em> ouvre une porte sur ces émotions asphyxiantes qu’on ne comprend pas toujours. À travers les voix des six personnages, le public devient témoin des enjeux que traversent au quotidien les adolescents. Chaque personnage a sa propre histoire, une part d’eux-mêmes qu’ils cachent à leurs amis, mais qu’ils révèleront ce soir-là, devant la caméra. On assiste à cette dichotomie entre la vérité et le masque.</div> 
                    </p>;

export default description;


