import React from 'react';
import  { Section } from '../Components';
import Emilie from '../images/EmilieBosse2.jpg';
import Melissa from '../images/MelissaCampeau.jpg';
import EricOlivier from '../images/ericolivierbosse.jpg';
import Mission from '../texts/mission';
import TextEmilie from '../texts/texteEmilie';
import TextMelissa from '../texts/texteMelissa';
import TextEricOlivier from '../texts/texteEric-Olivier';
import '../main.css';
import Person from '../Components/Person';

export default class Home extends React.Component {

    constructor(props) {
        super(props);
        
          this.state = {
              isOpen: false
          };
      }
    
      render() {
        return (
          <div className="infoContainer">
            <div className="info">
              <Section id="mission" title="Notre Mission" section="mission" subtitle={Mission}/>
            </div>
            <div className="info" style={{display: "flex", flexDirection: "column"}}>
            <Section id="equipe" title="Notre Équipe" section="equipe" />
            <Person title="Présidente, co-directrice artistique, auteure et interprète " name="Émilie Bossé" imagePath={Emilie} description={TextEmilie}/>
            <Person title="secrétaire et directrice technique " name="Mélissa Campeau" imagePath={Melissa} description={TextMelissa}/>
            <Person title="Développeur web et Trésorier " name="Éric-Olivier Bossé" imagePath={EricOlivier} description={TextEricOlivier}/>
            </div>

            
          </div>
        )
      }
}