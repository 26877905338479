import React from 'react';

export default class Parallax extends React.Component {

    render() {
        let divStyle = {
        /* The image used */
            'backgroundImage': 'url(' + this.props.image + ')',
            'backgroundSize': '100vw auto',
        /* Set a specific height */
            'minHeight': '28vw', 
        /* Create the parallax scrolling effect */
            'backgroundAttachment': 'fixed', 
            'backgroundRepeat': 'no-repeat'
        };
        return (
            <div className="parallax" style={divStyle}/>
        )
    }
}