import React from 'react';

let commentaireStyle={
    paddingBottom: 0,
    paddingTop: 0
  }

export default class Commentaire extends React.Component {
    render() {
        return (
            <div className="section-content" style={commentaireStyle}>
                <p>{"«" + this.props.citation + "»"}</p>
                {this.props.citation2 ? <p>{"«" + this.props.citation2 + "»"}</p> : <div/>}
                <p style={{fontWeight: "bold", fontStyle: "italic", textAlign: "left"}}>{this.props.credit}</p>
            </div>
        )
    }
}