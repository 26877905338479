import React from 'react';
import { Navbar, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import LogoSvg from '../images/logo.svg';
import Logotpv from '../images/logotpv.png';
import LogoConfessionnal from '../images/newSummary.jpg';
import { Link, animateScroll as scroll} from 'react-scroll';
import { NavLink as RouterLink, Route } from "react-router-dom";
import  { Home, Projects, Join } from '../pages';
import { Parallax } from '../Components';

export default class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            projectName: ""
        };
      }

    selectProject(name) {
        this.setState({projectName: name});
        this.scrollToTop();
    }

    selectProjectNoScroll(name) {
        this.setState({projectName: name});
    }

    selectImage = (name) => {
        if (name === "confessionnal") {
            return LogoConfessionnal;
        } else {
            return Logotpv;
        }
    }

    selectTab(name) {
        this.setState({tab: name});
    }

    scrollToTop = () => {
        scroll.scrollToTop();
    };

    render() {
        return (
            <React.Fragment>
                <Parallax image={this.selectImage(this.state.projectName)}/>
                <div className="header-container">
                    <Navbar color="faded" fixed="top" light expand="md">
                        <NavbarBrand tag={RouterLink} to="/" onClick={() => {this.scrollToTop(); this.selectProject("home"); this.props.selectBackground("texturedefault");}}>
                            <img src={LogoSvg} height="50px" alt={""}/>
                        </NavbarBrand>
                        <Nav navbar>
                            <NavItem >
                            <Link
                                    activeClass="active"
                                    to="mission"
                                    smooth={true}
                                    offset={-70}
                                    duration= {500}
                                    >
                                <NavLink tag={RouterLink} to="/" style={{fontWeight: "bold", color: "black"}} onClick={() => {this.selectProjectNoScroll("home"); this.props.selectBackground("texturedefault"); }}>
                                    Notre Mission
                                </NavLink>
                            </Link>
                            </NavItem>
                            <NavItem>
                                <Link
                                    activeClass="active"
                                    to="equipe"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration= {500}>
                                    <NavLink tag={RouterLink} to="/" style={{fontWeight: "bold", color: "black"}} onClick={() => {this.selectProjectNoScroll("home"); this.props.selectBackground("texturedefault");}}>
                                        Notre Équipe
                                    </NavLink>
                                </Link>
                            </NavItem>
                            <UncontrolledDropdown nav inNavbar >
                                <DropdownToggle nav caret style={{fontWeight: "bold", color: "black"}}>
                                    Nos Projets
                                </DropdownToggle>
                                <DropdownMenu right>
                                        <DropdownItem to="/nos-projets" onClick={() => {this.selectProject("confessionnal"); this.props.selectBackground("textureconfessionnal");}}  tag={RouterLink}>
                                        Le confessionnal
                                        </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                        <Nav className="ml-auto" navbar>
                            <NavItem>
                                    <NavLink to="/nous-joindre" tag={RouterLink} style={{fontWeight: "bold", color: "black"}} onClick={() => {this.scrollToTop(); this.selectProject("join"); this.props.selectBackground("texturedefault");}}>
                                        Nous Joindre
                                    </NavLink> 
                            </NavItem>
                        </Nav>
                    </Navbar>
                </div>
            <Route
              path="/"
              exact={true}
              component={Home}
            />
            <Route
                path="/nos-projets"
                render={() => <Projects projectName={this.state.projectName}/>}
                exact={true}
              />
              <Route
                path="/nous-joindre"
                component={Join}
              />
            </React.Fragment>
        );
    }
}

