import React from 'react';

export default class Person extends React.Component {
    render() {
        return (
            <div className={this.props.name}>
                <div className="section-content">
                    <div style={{}}>
                        <img src={this.props.imagePath} style={{height: 200, float: "left", marginRight: "15px"}} alt="member"/>
                        <p>{this.props.description}</p>
                    </div>
                    <div style={{clear: "left"}}>
                        <div className="resp-titre-fond-transparent">{this.props.name}</div>
                        <div className="resp-titre-fond-blanc" style={{textAlign: "left"}}>{this.props.title}</div>
                    </div>
                </div>
            </div>
        )
    }
}