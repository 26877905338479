const commentaire = [
    {
        citation: `Encore BRAVO pour cette pièce de théâtre « Le Confessionnal » avec de beaux textes qui reflètent bien la réalité des adolescents, de leurs préoccupations, de leurs peurs et de leurs aspirations face à leur avenir.  […] La pièce de théâtre « Le Confessionnal »  mérite d’être partagée et d’être vue par plusieurs adolescents de niveau secondaire 4 et 5.`,
        credit:"-Nicole Longchamps, psychologue de l’École secondaire Polybel"
    },
    {
        citation: "Merci pour le bel atelier d’hier et merci de nous partager votre histoire! J’ai beaucoup aimé votre approche.  Je n’ai eu que des retours positifs de nos élèves.",
        credit: "-Sandrine Reul, psychoéducatrice au Collège Stanislas"
    },
    {
        citation: "J’ai fait un retour avec les élèves et je n’ai eu que des commentaires positifs. J’enseigne depuis 20 ans à cette école et c’est rare qu’un atelier de ce genre suscite autant de réactions positives. Bravo!",
        credit: "-Dominique Blais, enseignante, École Marcelle Mallet"
    },
    {
        citation: "J'ai eu de très bons commentaires de la part des élèves et des adultes qui y ont assisté.",
        citation2: "Les sujets étaient tous pertinents, même que plusieurs jeunes se sont reconnus dans les personnages présentés dans le vidéo. On se donne rendez-vous pour l'an prochain!",
        credit: "-Valérie Blais, technicienne en travail social, Centre éducatif l’Abri"
    },
    {
        citation: "Bravo pour votre pièce et merci pour ces activités très enrichissantes pour les élèves. J’ai adoré la réflexion et la projection proposées.",
        credit: "-François Hallé, enseignant de l’Académie Lafontaine"
    },
    {
        citation: "C'était une belle initiative d'avoir proposé cette activité. J'ai trouvé la pièce vraiment bien faite et les comédiens étaient bons dans leur rôle. De plus, les sujets abordés touchent vraiment l'ensemble des étudiants et des jeunes adultes. J'espère que cette expérience pourra être répétée l'année prochaine.",
        credit: "-François Maltais, École secondaire des Lacs"
    },
    {
        citation: "J’ai, avec mon groupe, suivi du début à la fin cette pièce admirablement jouée. Les acteurs avaient sorti le grand art et rendaient vivante leur réalité aux ados. La mise en scène était tout simplement impeccable. Merci pour cette idée magistrale, les jeunes s’en souviendront longtemps.",
        credit: "-Jean Willer Marius, enseignant à l’École secondaire des Lacs"
    },
    {
        citation: "Quelle belle présentation! La participation des élèves lors du retour démontre que ces sujets pertinents sont traités avec intelligence et sensibilité dans le spectacle! Merci pour votre professionnalisme!",
        credit: "-Caroline Rouillard, psychoéducatrice, École secondaire de Mortagne"
    },
    {
        citation: "Tout est très positif ici suite à votre présentation.  Les enseignants ont beaucoup aimé et les élèves aussi. C’est la discussion avec vous tous qui a été le plus apprécié. [...] En souhaitant vous accueillir à nouveau l’an prochain!",
        credit: "-Diane Villeneuve, enseignante, École secondaire Cap-Jeunesse"
    },
    {
        citation: "Les sujets abordés ont rendu la pièce très évocatrice pour moi. Les propos actuels qui ont été abordés et les différentes perspectives à ceux-ci nous amenaient en tant que spectateur à se questionner à notre tour. Tout était amené dans l’optique de normaliser et c’est ce qui rendait le tout très pertinent à mon avis. Effectivement, ce que les jeunes disaient, je l’avais soit déjà dit, pensé ou entendu. Cela rendait le tout très concret.",
        credit: "-Une étudiante du Collège l’Assomption"
    },
];

export default commentaire;