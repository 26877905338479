import React from 'react';
import Commentaire from '../Components/Commentaire';

export default class Section extends React.Component {
    render() {
        return (
            <div className={"section" + this.props.section}>
                <div className="section-content" id={this.props.id}>
                    <h1 style={{textAlign: "left"}}>{this.props.title}</h1>
                    <p>{this.props.subtitle}</p>
                    <p>{this.props.citation}</p>
                    <p>{this.props.credit}</p>
                    <p>{this.props.citation2}</p>
                    <p>{this.props.credit2}</p>
                    {this.props.commentaires ? this.props.commentaires.map((object, i) => 
                        <Commentaire citation={object.citation} citation2={object.citation2} credit={object.credit} key={i} />
                    ) : <div/>}
                </div>
            </div>
        )
    }
}